import "../CSS/Body.css"

import { useLocation, useNavigate} from "react-router-dom";
import { useEffect, useState } from 'react';
import pdf from "../Components/IsaacJenemann.pdf";
function Menu() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const [isMenuVisible, setIsMenuVisible] = useState(window.innerWidth < 600);

  const handleResize = () => {
  if (window.innerWidth > 700) { // Set your breakpoint here
    setIsMenuVisible(true);
  } else {
    setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    handleResize(); // Check size on mount
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMenu = () => {
    if (
      (location.pathname.startsWith("/recent/") ||
      location.pathname.startsWith("/resume")) && isMenuVisible
    ) {
      return <></>;
    } else {
      return (
        <>
          <div className="initials">
            <a
              className={`menu-button ${
                location.pathname === "/" ? "active" : ""
              }`}
              id="initials"
              onClick={() => handleClick("/")}
            >
              {" "}
              ij{" "}
            </a>
          </div>
          <div className="menu" id="about">
            <a
              className={`menu-button ${
                location.pathname === "/about" ? "active" : ""
              }`}
              onClick={() => handleClick("/about")}
            >
              about
            </a>
          </div>
          <div className="menu" id="project">
            <a
              className={`menu-button ${
                location.pathname === "/recent" ? "active" : ""
              }`}
              onClick={() => handleClick("/recent")}
            >
              recent
            </a>
          </div>
          <div className="menu" id="contact">
            <a
              className={`menu-button ${
                location.pathname === "/contact" ? "active" : ""
              }`}
              onClick={() => handleClick("/contact")}
            >
              contact
            </a>
          </div>
          <div className="menu" id="resume">
            <a
              className={`menu-button ${
                location.pathname === "/resume" ? "active" : ""
              }`}
              onClick={() => handleClick("/resume")}
            >
              resume
            </a>
          </div>
        </>
      );
    }
  }
  return <>{renderMenu()}</>;
}

export default Menu;
