import "../CSS/Body.css";
import pdf from "../Components/IsaacJenemann.pdf";  

function Resume() {
  return (
    <>
      <iframe
        src={pdf}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="PDF Viewer"
        className="PDF"
      >
        This browser does not support PDFs. Please download the PDF to view it:{" "}
        <a href={pdf}>Download PDF</a>.
      </iframe>
    </>
  );
}

export default Resume;
