import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../CSS/Body.css";

function ProjectMenu({ nextImage, prevImage }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isProjectPage = location.pathname.startsWith("/recent/");
  const isResume = location.pathname.startsWith("/resume");

  // Timer duration in milliseconds
  const TIMER_DURATION = 5000; // 5 seconds

  const handleKeyDown = (event) => {
    if (isProjectPage) {
      if (event.key === "ArrowLeft") {
        prevImage();
      } else if (event.key === "ArrowRight") {
        nextImage();
      } else if (event.key === "Escape") {
        navigate("/recent");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Timer logic
    let timer;
    if (isProjectPage) {
      timer = setTimeout(() => {
        nextImage(); // Call nextImage to cue the next image
      }, TIMER_DURATION);
    }

    // Cleanup function to remove the event listeners and clear the timer
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearTimeout(timer);
    };
  }, [isProjectPage, prevImage, nextImage, navigate]); // Dependencies to rerun effect when these change

  return (
    <>
      {isProjectPage && (
        <>
          <a onClick={prevImage} className="project-button" id="prev-project">
            &#8249;
          </a>

          <a className="project-button" id="escape" href="/recent">
            exit
          </a>

          <a onClick={nextImage} className="project-button" id="next-project">
            &#8250;
          </a>
        </>
      )}

      {isResume && (
        <>
          <a className="project-button" id="escape" href="/home">
            exit
          </a>
        </>
      )}
    </>
  );
}

export default ProjectMenu;
